<template>
  <b-card>
    <div>
      <p class="form-label">
        <strong>{{activityDescLabel}}</strong>
        <br>
        {{activityDescData}}
      </p>
    </div>
    <div>
      <p class="form-label">
        <strong>{{atecoCodeLabel}}</strong>
        <br>
        {{atecoCodeData}}
      </p>
    </div>
  </b-card>
</template>

<script>

import { isNotEmpty } from '../../../utils/validators';

export default {
  name: 'ProfileTabsDataAteco',
  components: { },
  props: {
    data: Object,
  },
  computed: {
    notAvailableMsg() {
      return 'N.D.';
    },
    activityDescLabel() {
      return 'Descrizione attività italiano';
    },
    activityDescData() {
      if (isNotEmpty(this.data?.atecoInfo?.description)) {
        return this.data.atecoInfo.description;
      }
      return this.notAvailableMsg;
    },
    atecoCodeLabel() {
      return 'Ateco';
    },
    atecoCodeData() {
      // console.log('this.data?', this.data);
      if (this.data?.attribute.atecoCode) {
        return this.data?.attribute.atecoCode;
      }
      return this.notAvailableMsg;
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
